<template>
  <div class="col" v-loading="$store.getters['user/usersLoad']">
    <!-- search form -->
    <el-form ref="form" :model="form" style="max-width: 50rem">
      <el-form-item>
        <el-input
          v-model="form.search"
          :placeholder="$t('search')"
          style="font-size: 1rem"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- teachers  -->
    <el-row :gutter="20" dir="rtl">
      <!-- <el-col :xs="24" :lg="12" v-for="(teacher, i) in teachersData" :key="i">
        <Card :teacher="teacher" @activateTeacher="activateTeacher" />
      </el-col> -->
      <div class="handler_activated">
        <div
          class="handler_teach"
          v-for="(teacher, i) in teachersData"
          :key="i"
        >
          <Card
            :isNew="true"
            :teacher="teacher"
            @activateTeacher="activateTeacher"
            @deleteTeacher="deleteTeacher"
          />
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
import { Card } from "src/components/index";
import Swal from "sweetalert2";
export default {
  name: "show",
  components: { Card },
  data() {
    return {
      // search form
      form: {
        search: "",
      },
      teachersData: {},
    };
  },
  mounted() {
    // get users
    this.$store.dispatch("user/teachers", { query: {} }).then(() => {
      this.teachersData = this.$store.getters["user/users"];
    });
  },
  watch: {
    // perform local search
    "form.search": function () {
      if (this.form.search.length > 0) {
        // if user inputs search text, return match only
        this.teachersData = this.$store.getters["user/users"].filter(
          (teacher) =>
            teacher.name
              .toLowerCase()
              .includes(this.form.search.toLowerCase()) ||
            teacher.email.toLowerCase().includes(this.form.search.toLowerCase())
        );
      } else {
        // return all data since bno search
        this.teachersData = this.$store.getters["user/users"];
      }
    },
  },

  methods: {
    activateTeacher(id) {
      this.$store.dispatch("user/active", { id: id }).then((_) => {
        // get users again
        this.$store.dispatch("user/teachers", { query: {} }).then(() => {
          this.teachersData = this.$store.getters["user/users"];
        });
      });
    },

    deleteTeacher(id) {
      // using delete user api from user module due to user request to deactivate user when we try to deactivate a teacher
      Swal.fire({
        title: "تأكيد مسح المعلم ",
        text: "هل تريد مسح هذا المعلم ؟ ",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "تأكيد",
        cancelButtonText: "الغاء",
        buttonsStyling: false,
      }).then((date) => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("user/deleteNewTeacher", { user_id: id })
            .then((_) => {
              this.$store.dispatch("user/teachers", { query: {} }).then(() => {
                this.teachersData = this.$store.getters["user/users"];
              });
              Swal.fire({
                title: "تم المسح",
                text: "تم مسح بيانات هذا المعلم",
                icon: "success",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.handler_activated {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  padding: 10px;
  .handler_teach {
    @media (min-width: 767px) {
      width: 49%;
    }
    @media (min-width: 320px) and(max-width: 767px) {
      width: 100%;
    }
  }
}
</style>
